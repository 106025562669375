<template>
    <div class='top_header'>
        <header class="headers">
            <img src="../../assets/paizi.png" alt="">
        </header>
        <section class='sectios'>

            <div class="gcxq" v-for="(item,index) in lists" :key="index" :class="[item.type===1?'gcxqs':'gcxq']">
                <a class="as" @click="addto(item)">{{item.surveyName}}</a>
            </div>
           
        </section>
    </div>
</template>

<script>
    export default {
        name: '',
        components: {

        },
        props: {

        },
        data() {
            return {
                lists: [],
                code: '',
                cods: ''
            };
        },
        methods: {
            addto(item) {
                if (item.type === 1) {
                    window.location.href = 'javascript:;'
                    alert('该卷已答')
                } else {
                    window.location.href = item.path
                }
            }
        },
        computed: {

        },
        watch: {

        },
        async created() {
            console.log(this.$route.query);
            const answerId = this.$route.query.answerId
            const op = this.$route.query.op
            console.log(answerId);
            console.log(op);
            let result = await this.$API.reqMap({
                answerId: answerId,
                op: op
            })
            // this.lists = result
            // console.log(this.lists);
            if(result.code === 200){
                 this.lists = result.data
            }else if(result.code === 400){
                this.router.push('../Error_page/index.vue')
            }else{
                alert(result.msg)
            }
    document.body.removeChild(document.getElementById("Loading"));

        },
        mounted() {

        },
        beforeMount() {

        }
    };
</script>

<style scoped lang="scss">
    .top_header {
        width: 375px;
        height: 100%;

        .headers {
            padding-left: 18px;
        }

        .sectios {
            height: 100%;
            width: 100%;
            background: url(~@/assets/bg1.png);
            background-repeat: no-repeat;
            position: absolute;
            right: 0px;
            top: 0px;
            background-size: 100% 100%;
            // transform: rotateX(45deg);

            .gcxq {
                width: 70px;
                height: 60px;
                background-size: inherit;
                // background-image: url('../../assets/sheshi.png');
                 background-image: url('../../assets/bg2.png');
                line-height: 40px;
                text-align: center;
                position: absolute;
                left: 225px;
                top:90px;
                background-color: transparent;
                transform: scaleX(1.5);

                &:nth-child(1) {
                    position: absolute;
                    left: 100px;
                    top: 160px;
                }

                &:nth-child(2) {
                    position: absolute;
                    left: 290px;
                    top: 220px;
                }

                &:nth-child(3) {
                    position: absolute;
                    left: 20px;
                    top: 280px;
                }

                &:nth-child(4) {
                    position: absolute;
                    left: 230px;
                    top: 345px;
                }

                &:nth-child(5) {
                    position: absolute;
                    left: 60px;
                    top: 410px;
                }

                &:nth-child(6) {
                    position: absolute;
                    left: 250px;
                    top: 520px;
                }

                &:nth-child(7) {
                    position: absolute;
                    left: 100px;
                    top: 590px;
                }

                .as {
                    font-size: 13px;
                    // color: #fbfbfb;
                    color: rgba(0,0,0, 4);
                    text-decoration: none;
                    transform: rotate(24deg);
                    padding-top: 7px;
                    padding-left: 1px;
                    display: flow-root;
                    font-weight: 600;
                }
            }

            .gcxqs {
              
                background-image: url('../../assets/bg2.png');
                .as{
                     color: #ffffff;
                }

                
            }

            .as1 {
                color: #fbfbfb;
            }

            .esccl {
                position: absolute;
                left: 0px;
                top: 170px;
            }

            .gcxx {
                position: absolute;
                left: 190px;
                top: 223px;
            }

            .xxcd {
                position: absolute;
                left: 0px;
                top: 270px;
            }

            .xsfwrx {
                position: absolute;
                left: 203px;
                top: 330px;
            }

            .gcgc {
                position: absolute;
                left: 0px;
                top: 410px;
            }

            .jr {
                position: absolute;
                left: 200px;
                top: 450px;
            }

            .yb {
                position: absolute;
                left: 0px;
                bottom: 0px;
            }
        }

    }
</style>